import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const AboutWrapper = styled.div`
    width: 100%;
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 2px 2px 25px 0 rgba(0,0,0,.16);
    padding: 25px 40px;
    color: #34424a;
    display: grid;
    grid-column: 1 / -1;
    align-items: center;

    @media screen and (max-width: 600px) {
        grid-template-columns: 1fr;
        padding: 25px;
    }
`

const ImageContainer = styled.div`
    grid-columns: span 1;
    text-align: center;

    img {
        width: 100%;
        max-width: 200px;
        margin: 0 auto;
    }
`

const DescriptionContainer = styled.div`
    grid-column: span 2;
    font-size: 1em;
`

const About = ({ img, title, description }) => (
    <AboutWrapper>
        { img ? (<ImageContainer>
                <img src={img.src} alt={img.alt} scale="0" />
            </ImageContainer>) : ''
        }
        <DescriptionContainer>
            <h2>{title}</h2>
            <p>{description}</p>
        </DescriptionContainer>
    </AboutWrapper>
)

About.propTypes = {
    img: PropTypes.object,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
}

About.defaultProps = {
    img: {
        src: '',
        alt: ''
    }
}

export default About
