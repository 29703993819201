import React from 'react'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'

// components
import Layout from '../layouts/Layout'
import NavBar from '../components/NavBar'
import Overlay from '../components/Overlay'
import Card from '../components/blog/card'
import ContentBanner from '../components/blog/ContentBanner'
import Footer from '../sections/Footer'

const Wrapper = styled.div`
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
    background: url('/images/wood-bg.jpg');
    box-shadow: 0 12px 30px 0 rgba(0,0,0,0.11),
    0 5px 15px 0 rgba(0,0,0,0.08);
`

const Hero = styled.div`
    background-image: url('/images/banner.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    position: relative;
    color: #fff;
    padding: 10px 15px 100px;

    h1 {
        text-align: center;
        font-size: 47px;
        text-transform: uppercase;
        margin: 40px 0 55px;
        line-height: 1em;
        position: relative;
        z-index: 2222;

        &:after {
            content: "";
            display: block;
            background-image: -webkit-linear-gradient(180deg,#61a9e2,#815cec 50%,#f1e763);
            height: 4px;
            width: 140px;
            position: absolute;
            top: 60px;
            left: 0;
            right: 0;
            margin: 0 auto;
        }
    }
`

const ArticleContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-template-rows: auto;
    grid-gap: 25px;
    grid-template-rows: auto;
    position: relative;
    top: -125px;
    display: grid;
    padding: 40px 35px 0;
    z-index: 4444;
    transition: all .7s ease-in;

    @media screen and (max-width: 370px) {
        grid-template-columns: 1fr;
    }
`

const Blog = () => (
    <StaticQuery
        query={graphql`
            query {
                allContentfulBlog(limit: 20, sort: {fields: updatedAt, order: DESC}) {
                    nodes {
                      id
                      createdAt(fromNow: true)
                      slug
                      shortDescription {
                        shortDescription
                      }
                      status
                      title
                      featuredImage {
                        sizes {
                          srcSet
                          src
                        }
                        description
                      }
                    }
                    totalCount
                  }
            }
        `}
        render={data => (
            <Wrapper>
                <Layout siteTitle="Web development and design blog :: Raymond Ware" siteDescription="Web development and technology blog focused around modern development. ReactJS, VueJS, NodeJS, WordPress, Woocommerce, and more." />
                <Hero>    
                <Overlay bg="linear-gradient(to bottom right, rgba(61, 51, 96,1) 10%,rgba(85, 204, 162,0) 100%);" opacityVal=".98" display="none" mediaDisplay="2460px" />
                    <NavBar />
                    <h1>Blog</h1>
                </Hero>
                    <ArticleContainer>
                    {data.allContentfulBlog.nodes.map((item) => (
                        <Card 
                            key={item.id}
                            id={item.id}
                            img={item.featuredImage ? item.featuredImage : {
                                sizes: {
                                    srcSet: '',
                                    src: '/images/bg.jpg'
                                },
                                description: 'Placeholder image'
                            }}
                            title={item.title} 
                            date={item.createdAt}
                            description={item.shortDescription.shortDescription}
                            slug={item.slug} />
                        ))}
                        <ContentBanner 
                            title="Web Development related articles and tutorials" 
                            description="I am a Full Stack Developer & Designer with a passion for creating beautiful user interfaces. 
                            Here are some articles and resources that are focused around web development and design. Some main topics you can find here are on ReactJS, VueJS, NodeJS, WordPress, Woocommerce, and PHP. 
                            I am a developer who is constantly learning, join along and lets learn together." />
                    </ArticleContainer>
                <Footer />
            </Wrapper>
        )}
    />
)

export default Blog
