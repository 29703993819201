import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

const ArticleWrapper = styled.article`
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 2px 2px 25px 0 rgba(0,0,0,.16);
    padding: 0;
    color: #34424a;
    
    h2 {
        margin: 0;
        margin-bottom: 10px;
        line-height: 25px;
        color: #34424a;
    }

    h4 {
        font-size: .8em;
        color: #ccc;
        margin: 0 0 10px 0;
    }

    p {
        font-size: 16px;
        line-height: 18px;
        margin: 7px 0;
    }
`

const ImageOverlay = styled.div`
    width: 100%;
    height: 190px;
    border-radius: 7px 7px 0 0;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    display: flex;

    img {
        height: auto;
        text-align: center;
        width: 115%;
        margin: auto;
    }
`
const Content = styled.div`
    padding: 20px;
`

const Card = ({ id, img, slug, date, title, description }) => (
    <ArticleWrapper className={ `article-wrap${id}` }>
        <Link to={`/blog/${slug}`}>
            <ImageOverlay>
                <LazyLoadImage srcSet={img.sizes.srcSet} src={img.sizes.src} effect="blur" alt={img.description ? img.description : 'Blog post featured image'} />
            </ImageOverlay>
            <Content>
                <h4>{date}</h4>
                <h2>{title}</h2>
                <p>{description}</p>
            </Content>
        </Link>
    </ArticleWrapper>
)

export default Card

Card.propTypes = {
    id: PropTypes.string.isRequired,
    img: PropTypes.object.isRequired,
    slug: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
}
